@media screen and (max-width: 1187px) {
  .js #menu-top { display: none; }
  .js .selectnav {
  		display: block;
  		-webkit-appearance: none;
   		-moz-appearance: none;
   		appearance: none;
   		background: transparent url(../images/menu-icon.png) right 0px center no-repeat;
   		border: none;
		width: 40px;
		background-size: cover;
		border: none;
		width: 40px;
		background-size: cover;
		position: absolute;
		top: -70px;
		left: 20px;
  }

  #logo {
  	padding-left: 80px;
  }
}

@media screen and (max-width: 684px) {
	.primary-sidebar #right-menu {
		top: -110px;
	}

	footer .footer-icon {
    	width: 50%;
	}
}

@media screen and (max-width: 414px) {

	#logo {
  		padding-left: 60px;
  	}
	.primary-sidebar #right-menu {
		top: -110px;
		width: 120%;
		text-align: right;
		padding: 10px 0;

		li {
			float: none;
			display: inline;
		}
	}

	.sticky-container {
    	height: 0px !important;
	}
}


#logo {
	padding: 50px 0 20px;
}
#nav-bar {
	margin: 70px auto 0;
	background: #fff;
	padding: 10px 0;

	.dropdown.menu a {
		padding-right: 0.9rem;
		padding-left: 0.9rem;
	}
}

.bttmshadow {
	border-bottom: 2px solid #f7f7f7;
}

.sticky-container {
	height: 63.375px !important;
}

#menu-top {
	max-width: $global-width;
	margin: 0 auto;

	.menu-item {

		a {

			font-family: "Open Sans";
			font-size: 14px;
			font-weight: normal;
			line-height: 19px;
			text-transform: uppercase;
			color: #787676;

			&:hover {
				color:#333333;
			}
		}

		.is-dropdown-submenu {

			li {
				&:hover {
					background: #e3e3e3;
				}
				a {
					&:hover {
						color: #333333;
					}
				}
			}
		}
	}

	.active > a {
		background: white;
		color: #333333;
	}

}

.primary-sidebar {
	position: relative;
	#right-menu {
		padding: 10px 20px;
		list-style: none;
		display: inline;
		position: absolute;
		top: -40px;
		right: 0px;
		li {
			float: left;
			margin-left: 20px;

			a {
				color: #056FAD;
				&:hover {
					color: red;
				}

			}
		}
	}
}
.main {
	height: 1980px;
}

.selectnav { display: none; }

.sticky {
	z-index: 5;
}


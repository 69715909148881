.home {
	color: #3e3e3e;
	font-family: 'Quattrocento Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;

	#home-grid {
		padding-top: 60px;
		a {
			display: inline-block;
			color: #2a2a2a ;
			font-weight: bold;
			padding-top: 15px;
			&:hover {
				color: #f00;
			}
		}
	}
}


[type='file'], [type='checkbox'], [type='radio'] {
	margin-bottom: 0;
}

.single-product table.wccpf_fields_table td.wccpf_value input[type="radio"], .single-product table.wccpf_fields_table td.wccpf_value input[type="checkbox"] {
	width: 16px !important;
}

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.image {
	width: auto !important;
}

.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
	width: 68% !important;
}

.woocommerce div.product {
	position: inherit !important;
}

.woocommerce div.product div.images.woocommerce-product-gallery {
	z-index: -1 !important;
}

table.wccpf_fields_table td.wccpf_label, table.wccpf_fields_table td.wccpf_label label {
	width: 185px !important;
	padding-left: 10px;
}

table.wccpf_fields_table td {
	vertical-align: top !important;
}

.woocommerce form .form-row .input-checkbox {
	display: inline-block !important;
}

.wpcf7-form-control-wrap {
	position: inherit !important;
}

input::placeholder  {
	color: #3c3939;
}

span.wpcf7-list-item {
	display: block !important;
	margin: 0 0 10px 0!important;
}

.wpcf7-submit {
	background-color: #333;
	padding: 10px 15px;
	color: #fff;
	text-transform: uppercase;
	border: 1px solid #333;
	cursor: pointer;
	&:hover {
		background-color: #fff;
		color: #333;
	}
}

td {
	vertical-align: top;
}

.page-id-44 {
	td {
		p, div.paragraph {
			min-height: 80px;
		}

		.wsite-image {
			padding-top: 10px !important;
			padding-bottom: 10px !important;
		}
	}
}


#widget-home {
	padding-top: 60px;

	tbody {
    	border: 0px solid #f1f1f1;
    	background-color: #fff;
    }
}

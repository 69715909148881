
footer {
	border-top: 2px solid #f7f7f7;
	margin-top: 60px;
	padding-top: 20px;

	.footer-icon {
		width: 16%;
	}

	.data-contact {
		h2 {
			a {

				font-family: "Cabin" !important;
				font-weight: 700 !important;
				color: #2a2a2a !important;
				font-style: normal !important;
				text-transform: none !important;
				letter-spacing: 1px !important;
				display: inline-block;
			}
		}

		.social {
			display: block;
			text-align: center;
			a {
				display: inline;
				padding: 0 5px;
				color: #999;
				font-size: 18px;
				&:hover {
					color: #333;
				}
			}
		}
	}

	#sub-footer {
		border-top: 2px solid #f7f7f7;
		padding-top: 10px;
	}
}

#overlay {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(51, 51, 51, 0.68);

	#terms-pop {
		width: 900px;
		padding: 30px 50px 40px 40px;
		position: absolute;
		top: 20%;
		left: 50%;
		margin-left: -450px;
		background: #fff;
		height: 500px;
		overflow-y: scroll;
	}

	.close-button, .close-button.medium {
	    right: 30px;
	    top: 60px;
	    font-size: 5em;
	    line-height: 1;
	    color: white;
	}
}
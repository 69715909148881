.page {

	thead, tbody, tfoot {

		border: none;
		background-color: #fff;
	}

	h2 {
		color: #0971b0 ;
		font-family: "Cabin" !important;
		font-style: normal !important;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.2em;
	}

	a {
		color: #2a2a2a;
		&:hover {
			color: #f00;
		}
	}
}

#page-grid, #page-grid-above  {
	padding-top: 30px;
}

aside {
	padding-top: 100px;

	#sidebar > div {
		margin-bottom: 30px;
	}
}

.single-product {
	h2 {
		color: #0971b0 ;
		font-family: "Cabin" !important;
		font-style: normal !important;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.2em;
	}

	h3 {
		color: #000;
		font-family: "Cabin" !important;
		font-style: normal !important;
		font-size: 18px;
		font-weight: bold;
	}

	.group-title {
		margin: 20px 0 10px;
	}

	.product-note {
		color: red;
		font-size: 12px;
	}
}

.woocommerce{
	.cart {
		dl dt {
			margin-bottom: 0px !important;
		}

		dd p {
			line-height: 1.6 !important;
		}
	}

}

.woocommerce-checkout{
	#order_review{
		dl dt {
			margin-bottom: 0px !important;
		}

		dd p {
			line-height: 1.6 !important;
		}
	}

}

.woocommerce {
	.woocommerce-order-details  {
		li p {
			line-height: 1.6 !important;
		}
	}
}

.woocommerce-MyAccount-navigation {
	ul {
		list-style: none;
		li {
			border: 1px solid #ccc;
			border-bottom: 0px;
			a {
				background-color: #fafafa;
				padding: 10px;
				display: block;
			}
			&:last-child {
				border-bottom: 1px solid #ccc;
			}
		}
	}
}

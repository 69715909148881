@charset "UTF-8";

@import "config/custom-settings"; // Or copy the stuff you need to change to this file and uncomment
@import 'foundation';

//@include foundation-everything($flex: true);



$global-flexbox: true !global;


@include foundation-global-styles;

//@include foundation-grid;
//@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-flex-classes;


@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;




/*
  My custom styles:
  Add your scss files below to structure your project styles
*/


@import "site/print";
@import "site/fonts";
@import "site/original";
@import "site/wp-admin";
@import "site/header";
@import "site/topbar";
@import "site/home";
@import "site/pages";
@import "site/footer";
@import "site/responsive";